import React from 'react'
import Home from './component/Home'
import About from './component/About'
import Services from './component/Services'
import ContactUs from './component/ContactUs'
import Blog from './component/Blog'



import { BrowserRouter, Route, Routes} from 'react-router-dom';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
       <Route path="/" element={<Home/>} />
        <Route path="/about" element={<About/>}/>
        <Route path="/services" element={<Services/>}/>
        <Route path="/contact-us" element={<ContactUs/>}/>
        <Route path="/blog" element={<Blog/>}/>

       </Routes>
    </BrowserRouter>
  )
}

export default App
import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'

function Services() {


  return (
    <>
    <Navbar/>
  {/*        breadcrumb_area_three     */}
  <section
    className="breadcrumb_area_two breadcrumb_area_three parallaxie"
    data-background="assets/img/bg.jpg"
    style={{ background: "url(assets/img/case_one/cases_banner.jpg) no-repeat" }}>
    <div className="overlay" />
    <div className="container">
      <div className="breadcrumb_content">
        <h1>Services</h1>
        <p>Huge collection of stunning designs</p>
      </div>
    </div>
  </section>
  {/*        breadcrumb_area_three     */}
  
  {/*        portfolio_area     */}
  <section className="portfolio_area sec_pad">
    <div className="container">
      <div className="sec_title text-center">
        <h2 className="s_section_title">Our Services</h2>
      </div>
      <div className="row">
        <div className="col-lg-2 col-md-3">
          <ul className="list-unstyled gallery_filter">
            <li className="active" data-filter="*">
              All Projects
            </li>
            <li data-filter=".design">Design</li>
            <li data-filter=".market">Marketing</li>
            <li data-filter=".develop">Development</li>
            <li data-filter=".br">Branding</li>
          </ul>
        </div>
        <div className="col-lg-10 col-md-9">
          <div id="gallery" className="row gallery_inner">
            <div className="col-lg-4 col-sm-6 gallery_item design">
              <div className="gallery_post">
                <div className="img">
                  <img src="assets/img/case_one/case_pr_one.jpg" alt="" />
                </div>
                <div className="gallery_text_info">
                  <div className="gallery_content">
                    <a href="#">
                      <h3>Website Design & Development</h3>
                    </a>
                    <a href="#" className="g_tag">
                    <p>Are you looking for a website that would give you all this?</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 gallery_item br market">
              <div className="gallery_post">
                <div className="img">
                  <img src="assets/img/case_one/case_pr_two.jpg" alt="" />
                </div>
                <div className="gallery_text_info">
                  <div className="gallery_content">
                    <a href="#">
                      <h3>Google Adwords(PPC)</h3>
                    </a>
                    <a href="#" className="g_tag">
                    <p>The majority of people associate sponsored advertising with pay-per-click (PPC).</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 gallery_item design">
              <div className="gallery_post">
                <div className="img">
                  <img src="assets/img/case_one/case_pr_three.jpg" alt="" />
                </div>
                <div className="gallery_text_info">
                  <div className="gallery_content">
                    <a href="#">
                      <h3>Search Engine Optimization</h3>
                    </a>
                    <a href="#" className="g_tag">
                      <p>Get organic and significant traffic to your website.</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 gallery_item br market">
              <div className="gallery_post">
                <div className="img">
                  <img src="assets/img/case_one/case_pr_four.jpg" alt="" />
                </div>
                <div className="gallery_text_info">
                  <div className="gallery_content">
                    <a href="#">
                      <h3>Social Media Marketing</h3>
                    </a>
                    <a href="#" className="g_tag">
                     <p>Social media networks are available to everybody, allowing businesses to track the activity of their customers or potential customers.</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 gallery_item develop br">
              <div className="gallery_post">
                <div className="img">
                  <img src="assets/img/case_one/case_pr_five.jpg" alt="" />
                </div>
                <div className="gallery_text_info">
                  <div className="gallery_content">
                    <a href="#">
                      <h3>Graphics Designing</h3>
                    </a>
                    <a href="#" className="g_tag">
                     <p>Paper marketing is still required for those who walk-in to your workspace looking for solution.</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 gallery_item market">
              <div className="gallery_post">
                <div className="img">
                  <img src="assets/img/case_one/case_pr_six.jpg" alt="" />
                </div>
                <div className="gallery_text_info">
                  <div className="gallery_content">
                    <a href="#">
                      <h3>Mobile App Development</h3>
                    </a>
                    <a href="#" className="g_tag">
                      <p>You have a mobile app but don't know how to advertise it. We understand your vision</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*        portfolio_area     */}

  
  <Footer/>
</>

  )
}

export default Services
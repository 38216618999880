import React, { useState } from "react";

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };


  return (
    <>
      {/*        header     */}
    <header className="header_area p_absoulte m_p sticky_nav">
    <nav className="navbar navbar-expand-lg" id="header">
      <div className="container-fluid">
        <div className="logo_info">
          <a href="/" className="navbar-brand logo_two">
            <img src="assets\img\images\Untitled-design-3.png" srcSet="img/wlogo@2x.png 2x" alt="" />
            <img
              src="img/dark_logo.png"
              srcSet="img/dark_logo2x.png 2x"
              alt=""
            />
          </a>
          <div className="h_contact_info">
            <a href="#">+0074 215 2455</a>
            <a href="#">contact@wave.com</a>
          </div>
        </div>
        <button
          className="navbar-toggler collapsed"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="menu_toggle">
            <span className="hamburger">
              <span />
              <span />
              <span />
            </span>
            <span className="hamburger-cross">
              <span />
              <span />
            </span>
          </span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto menu">
            <li className="nav-item ">
            <a href="/" className="nav-link">
              Home
            </a>
            </li>
            <li className="nav-item ">
            <a href="/about" className="nav-link">
              About
            </a>
            </li>
            <li className="nav-item">
              <a href="/services" className="nav-link">
                Services
              </a>
            </li>
            <li className="nav-item">
              <a href="/blog" className="nav-link">
                Blog 
              </a>
            </li>
            <li className="nav-item ">
              <a href="/contact-us" className="nav-link">
                Contact &nbsp;&nbsp;&nbsp;
              </a>
            </li>
          </ul>
        </div>
        <div className={`burger_menu ${isMobileMenuOpen ? 'close_icon' : ''}`} onClick={toggleMobileMenu}>
          <div className="dot_icon">
            <span className="dot one" />
            <span className="dot two" />
            <span className="dot three" />
            <span className="dot four" />
            <span className="dot five" />
            <span className="dot six" />
            <span className="dot seven" />
            <span className="dot eight" />
            <span className="dot nine" />
          </div>
        </div>
      </div>
    </nav>
    </header>
    {/*        header     */}

    <div className={`hamburger_menu_wrepper ${isMobileMenuOpen ? 'mySideBar' : ''}`} id="menu">
        <div className="animation-box">
          <div className="top_menu">
            <a href="/">
              <img src="assets\img\images\Untitled-design-3.png" srcSet="img/wlogo@2x.png 2x" alt="logo" />
            </a>
            <div className="burger_menu close_icon" onClick={toggleMobileMenu}>
              <span className="text" data-text="Close" />
              <i className="icon_close" />
            </div>
          </div>
          <div className="menu-box navbar">
            <ul className="navbar-nav justify-content-end menu offcanfas_menu">
              <li className="nav-item active">
                <a  href="/" className="nav-link">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a href="/about" className="nav-link">
                   About
                </a>
              </li>
              <li className="nav-item">
                <a href="/services" className="nav-link">
                   Services
                </a>
              </li>
              
              <li className="nav-item">
                <a href="/blog" className="nav-link">
                  Blog
                </a>
              </li>
              <li className="nav-item">
                <a href="/contact-us" className="nav-link">
                   Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
